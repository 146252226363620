<template>
  <div class="Newsnotification">
    <div class="top-title">
      <div class="top-text">发布通知</div>
    </div>
     <div class="table-tap">
       <ul>
          <li v-for="(item,i) in taplist.one" :key="i"> <span @click="tap(item)" :class="classactive == item.tapid?'active':''">{{item.tapname}}</span></li>
        </ul>
    </div>
    <!-- 表单 -->
    <div class="Newsnotification-box">
      <el-form ref="form" :model="listpar" label-width="100px">

        <el-form-item label="通知内容:">
          <el-input type="textarea" v-model="listpar.bbbb" style="width:600px" rows="4"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" class="biy-icon" @click="onSubmit">提交</el-button>
        </el-form-item>
      </el-form>
    </div>


  </div>
</template>
<script>
import fakelist from "@/api/fakelist.js"
import apiurl from "@/api/apiurl/apiurl.js"
import config from "@/request/config.js"
export default {
  data() {
    return {
      // 上传图片的地址
      upLoadUrl:config.ImgUrl,
      
      listpar: {},

        classactive:1,
        taplist:{
         one:[
            {
               tapname:'会员',
               tapid:1,
            },
            {
               tapname:'商家',
               tapid:2,
            }, 
          ],}

    };
  },
  created() {
   
  },
  mounted() {},

  methods: {

      // 选项卡
      tap(e){
      this.currentPage = 1;
       this.classactive = e.tapid
      },



        //弹窗
      onSubmit(e){
          this.etype = e
          this.$confirm('是否确认提交', '消息提示', {
          distinguishCancelAndClose: true,
          confirmButtonText: '确认',
          cancelButtonText: '取消操作'
        }).then(() => {
          this.onSubmits()
          }).catch(action => {
            console.log("取消");
          })
      },

     async onSubmits(){
      	let res = await apiurl.entityDataHandle({
      FOREND_TOKEN:this.$common.baseGetToken(),//统一传递一下前端token 
      loginName:this.$common.baseGetuSername(),//登录账号 
      loginType:this.$common.baseGetSeparate(),//区分用户和商家1用户2商家
        bizCatalog:'NoticeEntity',
        handleMode:'upsert',
        bizData:{
          id:'',
          catalog:this.classactive,
          content:this.listpar.bbbb,
        }
        })
        if(this.$common.verifyAjaxResult(res,false)==false) return;
                 this.$alert('提交成功', '消息提示', {
          confirmButtonText: '确定',
          callback: action => {
            this.$router.push({ path: `/home/notification`});
          }
        });
      },
  },
  computed: {},
};
</script>


<style  scoped>
.Newsnotification .top-title{
  height: 80px !important;
}


.Newsnotification-box{
  width: 1000px;
  margin: 0 auto;
  padding-top: 50px
}
.biy-icon{
  margin-top: 50px;
  width: 500px;
}


.table-tap{
  width: 98%;
  margin: 0 auto;
  padding: 30px 0;
  border-bottom: 2px solid rgb(255, 187, 0);
}
.table-tap ul{
  display: flex;
  justify-content: left;
}
.table-tap ul li{
  width: 150px;
  text-align: center;
}
.table-tap ul li span{
font-size: 18px;
cursor: pointer;
transition: all .3s;
}
.table-tap ul li span.active{
  color: rgb(255, 187, 0);
  font-size: 16px;
}
@import "../../../assets/css/liststyle.css";
</style>